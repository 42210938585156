<template>
    <div class="milepost">
            <div class="milepost_left">
                 <treedata @projectId-child="projectIdClick"></treedata>
            </div>
            <div class="milepost_right">
                <div class="milepost_head">
                 <div class="milepost_head1">
                         <span>序号:</span>
                         <el-input placeholder="请输入内容" v-model="SerialNumber"></el-input>
                         <span>负责人:</span>
                         <el-input placeholder="请输入内容" v-model="UserName"></el-input>
                         <span>状态</span>
                            <el-select v-model="FinishType" placeholder="请选择">
                              <el-option
                                v-for="(item,index) in options"
                                :key="index"
                                :label="item"
                                :value="item">
                              </el-option>
                            </el-select>
                        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                        <el-button type="primary" icon="el-icon-refresh-right" @click="Reset">重置</el-button>
                    </div>
                    <div class="milepost_head2">
                        <el-button :disabled="disabledflag" type="primary" icon="el-icon-plus" @click="addmilepostDialog(0)">添加里程碑</el-button>
                    </div>
                </div>
            <div class="milepost_body">
                     <el-table
                        :data="tableData"
                        :header-cell-style="{
                            color: '#333333',
                            backgroundColor: '#FBFBFD',
                        }"
                        border
                        highlight-current-row
                        style="width: 100%">
                        <el-table-column
                            prop="SerialNumber"
                            label="序号"
                            sortable
                            align="center">
                        </el-table-column>
                        <el-table-column
                            prop="Event"
                            label="里程碑事件"
                            width="400"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            prop="UserName"
                            label="负责人"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            prop="FinishDate"
                            label="完成时间"
                            sortable
                            align="center">
                        </el-table-column>
                         <el-table-column
                            prop="Achievement"
                            label="交付成果"
                            align="center">
                        </el-table-column>
                         <el-table-column
                            prop="Standard"
                            label="检查标准"
                            align="center">
                        </el-table-column>
                        <el-table-column label="状态" align="center">
                            <template slot-scope="scope">
                                   <span class="span_box1" v-show="scope.row.FinishType=='已完成'?true:false"></span>
                                   <span class="span_box2" v-show="scope.row.FinishType=='进行中'?true:false"></span>
                                   <span class="span_box3" v-show="scope.row.FinishType=='已超期'?true:false"></span>
                                <el-dropdown>
                                    <span class="el-dropdown-link">
                                        {{scope.row.FinishType}}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item @click.native="complete(scope.row)">已完成</el-dropdown-item>
                                        <el-dropdown-item @click.native="hangintheair(scope.row)">未完成</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                            </el-table-column>
                           <el-table-column align="center"
                            prop="Remarks"
                            label="备注">
                        </el-table-column>
                          <el-table-column label="操作" align="center" width="200px">
                             <template slot-scope="scope">
                                    <el-link
                                    v-show="false==disabledflag?true:false"
                                    style="margin-right: 11px"
                                    type="text"
                                    :underline="false"
                                    @click.stop="editmilepostDialog(scope.row,1)"
                                    ><i class="el-icon-edit-outline"></i>编辑</el-link>
                                    <el-link
                                    v-show="false==disabledflag?true:false"
                                    style="margin-right: 11px"
                                    type="text"
                                    :underline="false"
                                    @click.stop="deletemilepostDialog(scope.row)"
                                    ><i class="el-icon-delete"></i>删除</el-link>
                                    <!-- <el-link
                                    style="margin-right: 10px"
                                    type="text"
                                    :underline="false"
                                    @click="seemilepostDialog(scope.row)"
                                ><img src="../../../assets/image/notice/query.png" alt="" style="width: 16px;height: 16px;vertical-align: -3px">查看</el-link> -->
                              </template>
                            </el-table-column>
                    </el-table>
            </div>
             <div>
                  <pagination
                       :total="total"
                       :page.sync="listQuery.pageNum"
                       :limit.sync="listQuery.pageSize"
                       @pagination="getList"
                          >
                  </pagination>
             </div>
        </div>
         <!-- 新增里程碑 -->
        <div class="milepostdialog">
                    <el-dialog
                    @close="closedialog('addForm')"
                    width="560px"
                    :visible.sync="milepostdialog" v-dialogDrag>
                    <div slot="title" class="header-title">
                        <span class="title-name"></span>
                        <span class="title-age">{{title}}</span>
                    </div>
                    <div class="body">
                        <el-form  label-width="110px" ref="addForm" :rules="rules" :model="obj">
                        <el-form-item label="里程碑事件:" prop="Event">
                            <el-input v-model="obj.Event" type="textarea"></el-input>
                        </el-form-item>
                      <el-form-item label="完成时间:" prop="FinishDate">
                            <el-date-picker
                            v-model="obj.FinishDate"
                            type="date"
                            placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="交付成果:" prop="Achievement">
                            <el-input v-model="obj.Achievement"></el-input>
                        </el-form-item>
                        <el-form-item label="检查标准:" prop="Standard">
                            <el-input v-model="obj.Standard" type="textarea"></el-input>
                        </el-form-item>
                        <el-form-item label="备注:">
                            <el-input v-model="obj.Remarks" type="textarea"></el-input>
                        </el-form-item>
                        </el-form>
                    </div>
                    <div class="btn">
                        <el-button @click="cancelmilepost('addForm')">取消</el-button>
                        <el-button @click="addmilepost('addForm')">确定</el-button>
                    </div>
                    </el-dialog>
        </div>
          <!-- 删除弹框 -->
         <div class="deletemilepostdialog">
            <el-dialog
                width="450px"
                :visible.sync="deletemilepostdialog" v-dialogDrag>
                <div slot="title" class="header-title">
                  <span class="title-name"></span>
                  <span class="title-age">提示</span>
                </div>
                <div class="body">
                  <span>!</span><span>此操作将永久删除该里程碑,是否继续？</span>
                </div>
                <div class="btn">
                  <el-button @click="deletemilepostdialog=false">取消</el-button>
                  <el-button @click="deletemilepost">确定</el-button>
                </div>
            </el-dialog>
         </div>
    </div>
</template>
<script>
import pagination from "../../../components/Pagination/index.vue"
import api from "../../../api/Grantsandmilestones"
import {mapState} from "vuex";
import treedata from "../../../components/treedata.vue"
import {ruleData2} from "../../../utils/validationRules"
export default {
    data(){
        return{
          SerialNumber:"",//序号
          UserName:"",//负责人
          FinishType:"",//状态
          options:["已完成","已超期","进行中"],
          rules: ruleData2,
          title:"添加里程碑",
          total:0,
          listQuery: {
              pageNum: 1,
              pageSize: 10,
          },
          obj:{
              Event:"",//事件
              FinishDate:"",//完成时间
              Achievement:"",//交付成果
              Standard:"",//检查标准
              Remarks:"",//备注
              State:0,
           },
          tableData: [],
          milepostdialog:false,//里程碑弹框
          deletemilepostdialog:false,//删除弹框
          flag:"",//用于判断打开的是新增还是修改0=新增|1=修改
          id:"",//储存里程碑id
        }
    },
    computed: mapState(["projectId","disabledflag"]),
    components:{
        treedata,
        pagination,
    },
    created(){
      this.milepost()
    },
    methods:{
         closedialog(formName){//关闭弹框回调
             this.$refs[formName].resetFields();
         },
         Reset(){//重置
            this.milepost()
            this.SerialNumber=""
            this.FinishType=""
            this.UserName=""
         },
         search(){//搜索
          let parame={
              UserName:this.UserName,
              SerialNumber:this.SerialNumber,
              FinishType:this.FinishType,
              TopicId:this.projectId,
              key:"",
              PageIndex: this.listQuery.pageNum*1-1*1,
              PageSize: this.listQuery.pageSize,
           }
           api.milepostData(parame).then(res=>{
                if(res.data.Status==1){
                  this.total=res.data.TotalRowsCount
                  this.tableData=res.data.Entity.map(item=>{
                      if(item.FinishDate){
                        item.FinishDate=item.FinishDate.substr(0,11)
                      }
                      return item
                  })
                }else{
                  alert(res.data.Message)
                }
                  console.log(res);
           })
         },
         projectIdClick(data) {
          //树型结构传递过来的课题id
          this.$store.commit("SaveId", data);
          this.milepost()
        },
        getList(val){//分页组件事件
          this.listQuery.pageNum=val.page
          this.milepost()
        },
        milepost(){//里程碑分页数据
           let parame={
               TopicId:this.projectId,
               key:"",
              PageIndex: this.listQuery.pageNum*1-1*1,
              PageSize: this.listQuery.pageSize,
           }
           api.milepostData(parame).then(res=>{
                if(res.data.Status==1){
                  this.total=res.data.TotalRowsCount
                  this.tableData=res.data.Entity.map(item=>{
                      if(item.FinishDate){
                        item.FinishDate=item.FinishDate.substr(0,11)
                      }
                      return item
                  })
                }else{
                  alert(res.data.Message)
                }
                  console.log(res);
           })
        },
        addmilepostDialog(flag){//打开添加里程碑弹框

           this.obj={
              Event:"",//事件
              FinishDate:"",//完成时间
              Achievement:"",//交付成果
              Standard:"",//检查标准
              Remarks:"",//备注
           },
          this.title="添加里程碑"
          this.milepostdialog=true
          this.flag=flag
        },
        addmilepost(formName){//确定添加里里程碑弹框
          if(this.flag==0){//添加项目
              this.$refs[formName].validate((valid) => {
              if (valid) {
                this.obj["TopicId"]=this.projectId
                this.obj["State"]=0
                let parame=this.obj
                    api.addmilepostData(parame).then(res=>{
                      console.log(res);
                      if(res.data.Status==1){
                          this.milepostdialog=false
                          this.milepost()
                          this.$message({
                                message: '添加成功',
                                type: 'success'
                          });
                      }else{
                        alert(res.data.Message)
                      }
                    })
              } else {
                 this.$message.error('注意必填项');
                return false;
              }
            });
          }
          if(this.flag==1){
             this.$refs[formName].validate((valid) => {
              if (valid) {
                  this.obj["Id"]=this.id
                  let parame=this.obj
                      api.UpdatemilepostData(parame).then(res=>{
                        console.log(res);
                        if(res.data.Status==1){
                            this.milepostdialog=false
                            this.milepost()
                            this.$message({
                                  message: '修改成功',
                                  type: 'success'
                            });
                        }else{
                          alert(res.data.Message)
                        }
                      })
              } else {
                 this.$message.error('注意必填项');
                return false;
              }
            });
          }
        },
        editmilepostDialog(item,flag){//打开编辑弹框
           this.title="修改里程碑"
           this.id=item.Id
           this.milepostdialog=true
           this.flag=flag
           this.obj.Event=item.Event
           this.obj.FinishDate=item.FinishDate
           this.obj.Achievement=item.Achievement
           this.obj.Standard=item.Standard
           this.obj.Remarks=item.Remarks
        },
        cancelmilepost(formName){//取消新增编辑弹框
           this.$refs[formName].resetFields();
            this.obj={
              Event:"",//事件
              FinishDate:"",//完成时间
              Achievement:"",//交付成果
              Standard:"",//检查标准
              Remarks:"",//备注
           },
           this.milepostdialog=false
        },
        deletemilepostDialog(item){//打开删除弹框
           this.id=item.Id
          this.deletemilepostdialog=true
        },
        seemilepostDialog(){//打开查看弹框
        },
        complete(item){//完成
          let parame={
             Id:item.Id,
             Flag:1,//flag=0表示未完成，flag=1表示完成
          }
          api.statemilepostData(parame).then(res=>{
            console.log(res);
               if(res.data.Status==1){
                    this.$message({
                         message: '状态更新成功',
                         type: 'success'
                    });
                  this.milepost()
               }else{
                   alert(res.data.Message)
               }
          })
        },
        hangintheair(item){
           let parame={
             Id:item.Id,
             Flag:0,//flag=0表示未完成，flag=1表示完成
          }
          api.statemilepostData(parame).then(res=>{
            console.log(res);
               if(res.data.Status==1){
                    this.$message({
                         message: '状态更新成功',
                         type: 'success'
                    });
                  this.milepost()
               }else{
                   alert(res.data.Message)
               }
          })
        },
        deletemilepost(){//删除里程碑
            let parame={
                Id:this.id,
            }
            api.DeletemilepostData(parame).then(res=>{
                  if(res.data.Status==1){
                    this.deletemilepostdialog=false
                    this.$message({
                         message: '删除成功',
                         type: 'success'
                    });
                    this.milepost()
                  }else{
                      alert(res.data.Message)
                  }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../../styles/mixin.scss";
.milepost{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    .milepost_left{
        width: 250px;
        margin-top: 2%;
        border-radius: 10px;
        background: white;
    }
    .milepost_right{
        width: 86%;
        height: 94%;
        margin-left: 24px;
        margin-top: 2%;
        margin-right: 24px;
        background: #FFFFFF;
        padding: 1% 1% 0 1%;
        border-radius: 10px;
        box-sizing: border-box;
        .milepost_head{
          display: flex;
            margin-bottom: 20px;
            .milepost_head1{
                width: 80%;
                 span{
                     margin-left: 10px;
                     margin-right: 10px;
                      @include add-size($font_size_16);
                 }
                .el-input{
                    width: 200px;
                }
                .el-button{
                    margin-left: 20px;
                }
            }
            .milepost_head2{
                width: 20%;
                text-align: right;
            }
        }
    }
}
.deletemilepostdialog{

  .body {
    text-align: center;
    span:nth-child(1) {
      display: inline-block;
      width: 26px;
      line-height: 26px;
      border-radius: 50%;
      background: #ffba00;
      text-align: center;
      color: #fff;
      margin-right: 10px;
      vertical-align: middle;
    }
    span:nth-child(2) {
      display: inline-block;
      vertical-align: middle;
      color: #666666;
      @include add-size1($font_size_18);
    }
  }
}
.span_box1{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #00E2C4;
    vertical-align: middle;
    margin-right: 6px;
}
.span_box2{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #FF9900;
    vertical-align: middle;
    margin-right: 6px;
}
.span_box3{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #FA5A8E;
    vertical-align: middle;
    margin-right: 6px;
}
    .milepost_body {
      width: 100%;
      height: 670px;
      overflow:auto;
    }
    ::-webkit-scrollbar {
    width: 10px; /*对垂直流动条有效*/
    height: 10px; /*对水平流动条有效*/
    }
    /*定义滚动条的轨道颜色、内阴影及圆角*/
    ::-webkit-scrollbar-track{
    background-color: #fff;
    border-radius: 3px;
    }
    /*定义滑块颜色、内阴影及圆角*/
    ::-webkit-scrollbar-thumb{
    border-radius: 7px;
    background-color: #1677FF;
    }
  //       /deep/.el-table__body tr.current-row>td{
  //   background-color: #f19944 !important;
  // /* color: #f19944; */  /* 设置文字颜色，可以选择不设置 */
  //  }
</style>
